import React from 'react';
import { Diamond, CreditCard } from 'lucide-react';

const PacksSection = () => (
  <section id="packs" className="mb-20">
    <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Packs de crédits</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      {[
        { title: "Pack Bronze", icon: <Diamond className="w-10 h-10 text-yellow-700" />, points: 8, price: 12, pricePerPoint: 1.5 },
        { title: "Pack Argent", icon: <Diamond className="w-10 h-10 text-gray-400" />, points: 40, price: 50, pricePerPoint: 1.25 },
        { title: "Pack Or", icon: <Diamond className="w-10 h-10 text-yellow-400" />, points: 120, price: 120, pricePerPoint: 1, featured: true },
        { title: "Pack Diamant", icon: <Diamond className="w-10 h-10 text-blue-400" />, points: 600, price: 450, pricePerPoint: 0.75 }
      ].map((pack, index) => (
        <div key={index} className={`bg-white shadow-lg rounded-lg p-6 text-center ${pack.featured ? 'ring-2 ring-blue-500 transform scale-105' : ''} hover:shadow-xl transition-all`}>
          <div className="flex justify-center mb-4">
            {pack.icon}
          </div>
          <h3 className="text-xl font-semibold mb-2">{pack.title}</h3>
          <p className="text-3xl font-bold mb-2 text-blue-600">{pack.points} pts</p>
          <p className="text-2xl font-semibold mb-2">{pack.price}€</p>
          <p className="text-sm text-gray-600 mb-6">{pack.pricePerPoint}€ par point</p>
          <a href="https://t.me/Fr_Links_Bot" target="_blank" rel="noopener noreferrer" className={`w-full py-2 px-4 rounded-full font-semibold flex items-center justify-center ${pack.featured ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'} hover:bg-blue-700 hover:text-white transition-colors`}>
            <CreditCard className="w-4 h-4 mr-2" />
            Créditer sur Telegram
          </a>
        </div>
      ))}
    </div>
  </section>
);

export default PacksSection;