import React from 'react';
import { CreditCard, FileText, Rocket } from 'lucide-react';

const FonctionnementSection = () => (
  <section id="fonctionnement" className="mb-20">
    <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Comment ça marche ?</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {[
        { step: "1", icon: <CreditCard className="w-8 h-8 text-blue-500" />, title: "Créditez votre compte", description: "Choisissez un pack et créditez votre compte directement via Telegram." },
        { step: "2", icon: <FileText className="w-8 h-8 text-green-500" />, title: "Passez votre commande", description: "Utilisez le formulaire simple dans Telegram pour spécifier vos besoins en backlinks." },
        { step: "3", icon: <Rocket className="w-8 h-8 text-purple-500" />, title: "Boostez votre SEO", description: "Recevez vos backlinks de qualité en moins de 5 jours et améliorez votre référencement." }
      ].map((item, index) => (
        <div key={index} className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow">
          <div className="w-12 h-12 bg-blue-600 text-white rounded-full flex items-center justify-center text-2xl font-bold mb-4">
            {item.step}
          </div>
          <div className="flex justify-center mb-4">
            {item.icon}
          </div>
          <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
          <p className="text-gray-600">{item.description}</p>
        </div>
      ))}
    </div>
  </section>
);

export default FonctionnementSection;