import React from 'react';
import Header from './components/Header';
import IntroSection from './components/IntroSection';
import FonctionnementSection from './components/FonctionnementSection';
import OffresSection from './components/OffresSection';
import PacksSection from './components/PacksSection';
import CTASection from './components/CTASection';
import Footer from './components/Footer';
import { Helmet } from 'react-helmet';


const HomePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 text-gray-900">
      <Helmet>
        <title>Links Bot - Backlinks de qualité via Telegram</title>
        <meta name="description" content="Obtenez des backlinks de qualité pour votre site via notre service Telegram" />
        <meta property="og:title" content="Links Bot - Backlinks de qualité via Telegram" />
        <meta property="og:description" content="Obtenez des backlinks de qualité pour votre site via notre service Telegram" />
        <meta property="og:image" content="https://www.votresite.com/images/og-image.jpeg" />
        <meta property="og:url" content="https://www.votresite.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Links Bot - Backlinks de qualité via Telegram" />
        <meta name="twitter:description" content="Obtenez des backlinks de qualité pour votre site via notre service Telegram" />
        <meta name="twitter:image" content="https://www.votresite.com/images/og-image.jpeg" />
      </Helmet>
      <Header />
      <main className="container mx-auto px-4 py-12">
        <IntroSection />
        <FonctionnementSection />
        <OffresSection />
        <PacksSection />
        <CTASection />
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;