import React from 'react';
import { Send } from 'lucide-react';

const Footer = () => (
  <footer className="bg-gray-800 text-white py-12">
    <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8">
      <div>
        <h3 className="text-xl font-semibold mb-4">Links Bot</h3>
        <p className="text-gray-400">Votre solution de backlinks sur Telegram</p>
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-4">Liens rapides</h3>
        <ul className="space-y-2">
          <li><a href="/" className="text-gray-400 hover:text-white transition-colors">Accueil</a></li>
          <li><a href="#fonctionnement" className="text-gray-400 hover:text-white transition-colors">Fonctionnement</a></li>
          <li><a href="#offres" className="text-gray-400 hover:text-white transition-colors">Offres</a></li>
          <li><a href="#packs" className="text-gray-400 hover:text-white transition-colors">Packs</a></li>
        </ul>
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-4">Nous contacter</h3>
        <p className="text-gray-400">Support et commandes via notre bot Telegram</p>
        <a href="https://t.me/Fr_Links_Bot" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300 transition-colors flex items-center mt-2">
          <Send className="w-4 h-4 mr-2" />
          @your_links_bot
        </a>
      </div>
    </div>
    <div className="container mx-auto px-4 mt-8 pt-8 border-t border-gray-700 text-center text-gray-400">
      <p>&copy; 2024 Links Bot. Tous droits réservés.</p>
    </div>
  </footer>
);

export default Footer;