import React from 'react';
import { Send } from 'lucide-react';

const CTASection = () => (
  <section className="text-center mb-20">
    <h2 className="text-3xl font-bold mb-6 text-gray-800">Prêt à booster votre SEO ?</h2>
    <p className="text-xl mb-8 text-gray-600">Créditez votre compte et commandez vos premiers backlinks dès maintenant !</p>
    <a href="https://t.me/Fr_Links_Bot" target="_blank" rel="noopener noreferrer" className="inline-flex items-center bg-green-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-green-700 transition-colors">
      Lancer Links Bot sur Telegram
      <Send className="ml-2 w-5 h-5" />
    </a>
  </section>
);

export default CTASection;