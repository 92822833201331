import React from 'react';
import { Rocket, Zap, Package, ChevronRight } from 'lucide-react';

const OffresSection = () => (
  <section id="offres" className="mb-20">
    <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Nos offres de backlinks</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {[
        { title: "Starter", icon: <Rocket className="w-12 h-12 text-blue-500" />, points: 4, features: ["TF > 15", "RD > 30", "Texte de 800 mots min.", "Image de qualité incluse", "Livraison en moins de 5 jours"] },
        { title: "Pro", icon: <Zap className="w-12 h-12 text-yellow-500" />, points: 8, features: ["TF > 18", "RD > 50", "Trafic > 5", "Texte de 1000 mots min.", "Choix de l'ancre", "Livraison en moins de 5 jours"] },
        { title: "Expert", icon: <Package className="w-12 h-12 text-green-500" />, points: 16, features: ["TF > 20", "RD > 90", "Trafic > 200", "Texte de 1200 mots min.", "Choix de la thématique", "Mot-clé en début de titre", "Livraison en moins de 5 jours"] }
      ].map((offer, index) => (
        <div key={index} className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow">
          <div className="flex flex-col items-center mb-6">
            {offer.icon}
            <h3 className="text-2xl font-semibold mt-4">{offer.title}</h3>
            <p className="text-3xl font-bold text-blue-600 mt-2">{offer.points} points</p>
          </div>
          <ul className="space-y-2">
            {offer.features.map((feature, featureIndex) => (
              <li key={featureIndex} className="flex items-center">
                <ChevronRight className="w-5 h-5 text-green-500 mr-2" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
    <div className="mt-8 text-center text-gray-600">
      <p>1 point = entre 1,5€ et 0,75€ en fonction du pack acheté</p>
      <p>Possibilité de choisir son ancre, sa thématique et son mot-clé (présent en début de titre)</p>
    </div>
  </section>
);

export default OffresSection;