import React, { useState } from 'react';
import { Send, Menu, X } from 'lucide-react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-white shadow-md w-full sticky top-0 z-50">
      <div className="w-full px-4 py-6 flex justify-between items-center">
        <div className="flex items-center">
          <Send className="w-8 h-8 text-blue-600 mr-2" />
          <div>
            <h1 className="text-3xl font-bold text-blue-600">Links Bot</h1>
            <p className="text-sm text-gray-600">Backlinks de qualité via Telegram</p>
          </div>
        </div>
        <div className="lg:hidden">
          <button onClick={toggleMenu} aria-label="Toggle menu">
            {isMenuOpen ? <X className="w-8 h-8 text-blue-600" /> : <Menu className="w-8 h-8 text-blue-600" />}
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="lg:hidden w-full">
          <nav className="bg-white shadow-md p-4">
            <ul className="space-y-4">
              <li><a href="#fonctionnement" className="block text-blue-600 hover:text-blue-800 transition-colors">Fonctionnement</a></li>
              <li><a href="#offres" className="block text-blue-600 hover:text-blue-800 transition-colors">Offres</a></li>
              <li><a href="#packs" className="block text-blue-600 hover:text-blue-800 transition-colors">Packs</a></li>
              <li><a href="https://t.me/Fr_Links_Bot" target="_blank" rel="noopener noreferrer" className="block bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition-colors flex items-center">
                <Send className="w-4 h-4 mr-2" />
                Démarrer sur Telegram
              </a></li>
            </ul>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
