import React from 'react';
import { Send } from 'lucide-react';

const IntroSection = () => (
  <section className="mb-20 text-center">
    <h2 className="text-4xl font-bold mb-6 text-gray-800">Propulsez votre SEO avec des backlinks de qualité</h2>
    <p className="text-xl mb-8 text-gray-600 max-w-2xl mx-auto">
      Grâce à notre réseau de sites et à celui de nos partenaires, obtenez des backlinks puissants pour booster votre référencement. Créditez votre compte et commandez facilement via notre bot Telegram.
    </p>
    <a href="https://t.me/Fr_Links_Bot" target="_blank" rel="noopener noreferrer" className="inline-flex items-center bg-blue-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-blue-700 transition-colors">
      Commencer sur Telegram
      <Send className="ml-2 w-5 h-5" />
    </a>
  </section>
);

export default IntroSection;